@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);

.switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 21px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(15px);
    transform: translateX(15px);
  }
  
  .slider.round {
    border-radius: 50px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
.bubble_chart {
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    padding-bottom: 100%;
    /* aspect ratio */
    vertical-align: top;
    overflow: hidden;
    top: 0;
    left: 0;
  }

  .bubbleImage{
    cursor: pointer;
  }
  
  .svg-content-responsive {
    display: inline-block;
    position: absolute;
    top: 20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }

div.tooltip {	
  position: absolute;			
  text-align: center;		
  padding: 5px 20px;			
  font: 12px sans-serif;
  border: 1px #E7D0F1 solid;
  background: #FFFFFF;	
  border-radius: 4px;			
  pointer-events: none;		
}

div.tooltip h3{
  font-family: "Mulish", sans-serif;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  line-height: 1.25;
  margin-bottom: 4px;
}

div.tooltip h2{
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin:0;
  line-height: 1.25;
}

circle{
  fill: transparent;
}

.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover{
  color: #000 !important;
}
