.bubble_chart {
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    padding-bottom: 100%;
    /* aspect ratio */
    vertical-align: top;
    overflow: hidden;
    top: 0;
    left: 0;
  }

  .bubbleImage{
    cursor: pointer;
  }
  
  .svg-content-responsive {
    display: inline-block;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

div.tooltip {	
  position: absolute;			
  text-align: center;		
  padding: 5px 20px;			
  font: 12px sans-serif;
  border: 1px #E7D0F1 solid;
  background: #FFFFFF;	
  border-radius: 4px;			
  pointer-events: none;		
}

div.tooltip h3{
  font-family: "Mulish", sans-serif;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  line-height: 1.25;
  margin-bottom: 4px;
}

div.tooltip h2{
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin:0;
  line-height: 1.25;
}

circle{
  fill: transparent;
}

.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover{
  color: #000 !important;
}